const AdminMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/admin',
        activeMenuFor: ['/dashboard/admin']
    },
    {
        ds_nm: 'Job Listing',
        url: '/job/listing',
        activeMenuFor: ['/job/listing']
    },
    {
        ds_nm: 'Student Enrolments',
        url: '/enrolment/list/university',
        activeMenuFor: ['/enrolment/list/university']
    },
]
export default AdminMenuData