import React from 'react';
import { __getImage } from '../../_factory/globalService';
const ConfirmDialog = ({ isTitle, title, body, onYesClickCallBack, onNoClickCallBack }) => {
    const handleClickYes = (e) => {
        onYesClickCallBack(e)
    }
    const handleClickNo = (e) => {
        onNoClickCallBack(e)
    }
    return (
        <div className='dialog_wrapper'>
            <div className='dialog_inner'>
                <div className='dialog_content'>
                    {isTitle && <div className={`modal_title confirm_msg`}>
                        <div className='title_text'>
                            {title}
                        </div>
                    </div>}
                    {body && <div className='modal-body text-center mb-20'>
                        {body}
                    </div>}
                    {<div className='bodal-footer'>
                        <div className='d-flex justify-content-center'>
                            {<button className={`btn custom_btn btn_next mr-20`} type='button' onClick={() => { handleClickYes(true) }}>Yes</button>}
                            {<button className={`btn custom_btn btn_transparent ml-20`} type='button' onClick={() => { handleClickNo(false) }}>No</button>}
                        </div>

                    </div>}
                </div>
            </div>
        </div>
    )
}
export default ConfirmDialog;