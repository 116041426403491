import axios from "axios";
import { apiConfig } from "./apiConfig";
import fileDownload from 'js-file-download';
import { __getLoginDetail } from "./globalService";
const apiClient = axios.create({
    baseURL: `${apiConfig.apiBaseUrl}`,
    headers: {
        "Content-type": "application/json"
    }
});
(async () => {
    apiClient.interceptors.request.use(
        function (req) {
            const userData = __getLoginDetail();
            if (userData) {
                const userId = userData?.id
                req.headers['Userid'] = userId;
            }
            const loader = document.getElementById("loader");
            loader.style.display = "block";
            req.time = { startTime: new Date() };
            return req;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    apiClient.interceptors.response.use(
        function (res) {
            setTimeout(() => {
                const loader = document.getElementById("loader");
                if (loader && loader.style.display == "block") {
                    document.getElementById("loader").style.display = "none";
                }
            }, 300);
            res.config.time.endTime = new Date();
            res.duration = res.config.time.endTime - res.config.time.startTime;
            return res;
        },
        (err) => {
            const loader = document.getElementById("loader");
            if (loader) {
                document.getElementById("loader").style.display = "none";
            }
            return Promise.reject(err);
        }
    );
})();

export const HttpFetch = async (url, param = {}, optoin = {}) => {
    const res = await apiClient.get(url, { params: { ...param || {} } }, { ...optoin });
    return res
}
export const HttpPost = async (url, body) => {
    const res = await apiClient.post(url, { ...body });
    return res
}

export const HttpPut = async (url, body) => {
    const res = await apiClient.put(url, { ...body });
    return res
}

export const HttpDelete = async (url, optoin = {}) => {
    const res = await apiClient.delete(url, { ...optoin });
    return res
}
export const HttpPostForm = async (url, file) => {
    const baseURL = `${apiConfig.apiBaseUrl}`;
    const res = await axios.post(`${baseURL}${url}`, file, {
        headers: { "Content-type": "multipart/form-data" },
    })
    return res
}

export const HttpDownloadFile = async (url, fileName) => {
    const res = await apiClient.get(url, {
        responseType: 'blob'
    });
    return fileDownload(res.data, fileName)
}