import { useNavigate, useParams } from 'react-router-dom';
import { __localStorageGet } from '../_factory/globalService';
import SideNavBar from '../Components/SideNavBar';
import { routerConfig } from '../_factory/routerConfig';
import { NavData } from '../Components/SideNavBar/Data';
import Header from '../Components/Header';
const LayoutWithSideNav = ({ authGuard, children, isSideNav, isByPassLogin }) => {
    const userData = __localStorageGet('userDataTM');
    const _jwt = userData && userData.token;
    const loginType = userData && userData.type;
    const navigate = useNavigate();
    const HandleAllowedRoutes = () => {
        return (
            <>
                <div className={`page_wrapper ${isSideNav ? ' side_nav' : ''}`}>
                    {isSideNav && <SideNavBar />}
                    <div className='page_content'>
                    <Header />
                        {children}
                    </div>
                </div>
            </>
        )
    }

    const HandleNotAllowedRoutes = () => {
        let _url = routerConfig.login.route;
        setTimeout(() => {
            navigate(_url)
        }, 100)
    }
    if (isByPassLogin) {
        if(!_jwt){
            return HandleNotAllowedRoutes()
        }else{
            return HandleAllowedRoutes()
        }
    } else {
        if (!authGuard) {
            return HandleAllowedRoutes()
        } else if (authGuard && !_jwt) {
            return HandleNotAllowedRoutes()
        } else {
            let isAuthorize = false;
            let tempMenuData = NavData[loginType];
            tempMenuData.map((item) => {
                if (item.url && item.url != '/') {
                    if (window.location.href.indexOf(item.url) > -1) {
                        isAuthorize = true
                        return
                    }
                } else {
                    isAuthorize = false
                }
            })
            if (isAuthorize) {
                return HandleAllowedRoutes()
            } else {
                return HandleNotAllowedRoutes()
            }

        }
    }
}

export default LayoutWithSideNav;