import React from 'react';
import es from '../enrolment.module.scss';
import { __ExportToExcel, __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import Card from '../../../Components/Card';
import { __httpEnrolmentList } from '../enrolmentService';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import { Link } from "react-router-dom";
import { __httpAllUserList } from '../../../_factory/masterService';
import { AutoComplete } from 'primereact/autocomplete';
import Paging from '../../../Components/Paging';
import ImportFileComponent from '../../../Components/form/ImportFileComponent';
import TableSearchComponent from '../../../Components/TableSearch';
const EnrolmentListing = () => {
    const [listData, set_listData] = React.useState(null);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [isImportFile, set_isImportFile] = React.useState(false);
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    /* autocomplete */
    const [userList, set_userList] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [filteredUserData, setFilteredUserData] = React.useState(null);
    /*  */
    const [type, set_type] = React.useState(null);
    const [loginType, set_loginType] = React.useState(null);
    const [searchTitle, set_searchTitle] = React.useState('Search');
    const [searchParam, set_searchParam] = React.useState({});
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [searchInput, set_searchInput] = React.useState('');
    React.useEffect(() => {
        let userData = __getLoginDetail();
        if (userData?.type == 'superadmin') {
            set_searchTitle('Filter by University Wise')
            set_type('university');
            set_loginType(userData?.type)
            getAllUser('university')
        } else {
            getListingData(searchParam);
        }
    }, []);
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])

    React.useEffect(() => {
        filterListingData(searchInput)
    }, [listData])

    const filterListingData = (val = '') => {
        let filteredData = [];
        if (val === "") {
            filteredData = listData;
        } else {
            filteredData = listData.filter((item) => {
                let name = item.name.toLowerCase()
                return name.includes(val.toLowerCase());
            });
        }
        set_filteredListData(filteredData)
    }
    const handleSearch = (value) => {
        set_searchInput(value);
        filterListingData(value)
    }

    const getAllUser = (searchUserType) => {
        let payload = {
            "name": "",
            "type": searchUserType
        }
        __httpAllUserList(payload).then(res => {
            if (res.data.isSuccess) {
                let responseData = res.data.responseData;
                set_userList(responseData)
                set_isDataLoaded(true)
            }
        })
    }

    const getListingData = (payload) => {
        let temp = [];
        __httpEnrolmentList(payload).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = []
            }
            set_listData(temp)
            set_isDataLoaded(true)
        })
    }
    const handleDataupload = (config) => {
        closeImportFile();
        set_dialogConfig(config)
    }
    const handleOnClickCallBack = (e) => {
        if (e && e.status) {
            getListingData()
        }
        set_dialogConfig(null)
    }
    const openImportFile = () => {
        set_isImportFile(true)
    }
    const closeImportFile = () => {
        set_isImportFile(false)
    }
    var tableToExcel = (function () {
        var uri = "data:application/vnd.ms-excel;base64,",
            template =
                '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><?xml version="1.0" encoding="UTF-8" standalone="yes"?><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) {
                return window.btoa(unescape(encodeURIComponent(s)));
            },
            format = function (s, c) {
                return s.replace(/{(\w+)}/g, function (m, p) {
                    return c[p];
                });
            };
        return function (table, name) {
            if (!table.nodeType) table = document.getElementById(table);
            var ctx = {
                worksheet: name || "Worksheet",
                table: table.innerHTML,
            };
            var link = document.createElement("a");
            const fileName = `Student List.xls`;
            link.download = fileName;
            link.href = uri + base64(format(template, ctx));
            link.click();
        };
    })();

    const handleAutoCompleteChange = (e) => {
        setSelectedUser(e.value)

    }
    const handleONSelect = (e) => {
        let param = {
            id: e.value.id || ''
        }
        set_searchParam(param)
        getListingData(param);
    }

    const search = (event) => {
        setTimeout(() => {
            let _filteredUserData;
            if (!event.query.trim().length) {
                _filteredUserData = [...userList];
            }
            else {
                _filteredUserData = userList.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUserData(_filteredUserData);
        }, 250);
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const ExportToExcel = (type, id, name) => {
        __ExportToExcel(type, id, name)
    }
    return (<>
        <>
            <Card>
                <div className={es['enrollment_wrapper']}>
                    <div className='row plr-15'>
                        {loginType == 'superadmin' ? <div className='col-md-4 col-sm-12'>
                            <div className='form-group'>
                                <div className='search_bar'>
                                    <div className='custom-autocomplete width_icon search_inner'>
                                        <img className={`search_icon ${es['search_icon']}`} src={__getImage('search_icon.svg')} />
                                        <AutoComplete field="name" value={selectedUser} suggestions={filteredUserData} completeMethod={search} onChange={(e) => handleAutoCompleteChange(e)} placeholder={searchTitle} onSelect={(e) => { handleONSelect(e) }} />
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                        <div className='col-md-4 col-sm-12'>
                            <div className='form-group'>
                                <TableSearchComponent placeholder='Search by name' searchInput={searchInput} handleSearch={handleSearch} />
                            </div>
                        </div>
                    </div>
                    <div className={es['page_header']}>
                        <div>
                            <div className={es['page_title']}>
                                List of Enrolments
                            </div>
                            {isDataLoaded && listData && <div className={es['count']}>{listData.length} applications</div>}
                        </div>
                        <div className='d-flex'>
                            {loginType !== 'superadmin' && <button type='button' className={es['btn_primary']} onClick={(e) => { openImportFile() }} id="btnExport">
                                <img src={__getImage('filtersearch.svg')} />
                                Import
                            </button>}
                            {loginType !== 'superadmin' && <button type='button' className={`ml-10 ${es['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', 'enrolmentTable', 'Enrolment List') }}>
                                <img src={__getImage('filtersearch.svg')} />
                                Download
                            </button>}
                            <button type='button' className={`ml-10 ${es['btn_primary']}`}>
                                <img src={__getImage('filtersearch.svg')} />
                                Filter
                            </button>
                            <button type='button' className={`ml-10 ${es['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', 'enrolmentTable', 'Enrolment List') }}>
                                <img src={__getImage('exportsquare.svg')} />
                                Export
                            </button>
                        </div>
                    </div>
                    <div className={`table_wrapper scrollBar ${es['table_wrapper']}`}>
                        <table id="enrolmentTable">
                            <thead>
                                <tr>
                                    <th>
                                        <div className='content'>
                                            Name
                                        </div>
                                    </th>
                                    <th>
                                        <div className='content'>
                                            Department
                                        </div>
                                    </th>
                                    <th>

                                        <div className='content'>
                                            Email
                                        </div>
                                    </th>
                                    <th>
                                        <div className='content'>
                                            Careers Matched
                                        </div>
                                    </th>
                                    <th>
                                        <div className='content'>
                                            Position
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isDataLoaded ? <tr>
                                    <td colSpan={100}>
                                        <div className='content'>

                                        </div>
                                    </td>
                                </tr> : <>
                                    {filteredListData && filteredListData.length > 0 ? <>
                                        {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                            return (
                                                <tr key={`en_${i}`}>
                                                    <td>
                                                        <Link to={`/enrolment/student/detail/${ele.id}`}>
                                                            <div className='d-flex pointer'>
                                                                <div className={es['thumb']}>
                                                                    <img src={__getImage('Group.png')} />
                                                                </div>
                                                                <div className='content'>
                                                                    {ele.name}
                                                                    <p className={es['light']}>
                                                                        Fresher
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.department}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.email}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.careersMatch}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='content'>
                                                            {ele.position}
                                                            <p className={es['light']}>
                                                                10 mins
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </> : <>
                                        <tr>
                                            <td colSpan={100}>
                                                <div className='content'>
                                                    No records...
                                                </div>
                                            </td>
                                        </tr>
                                    </>}
                                </>}
                            </tbody>
                        </table>
                    </div>
                    {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                        <div></div>
                        <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                    </div> : ''}
                </div>
            </Card>
            {isImportFile && <DialogWrapper className={'width_6 00'}>
                <div className='modal-header'>
                    <h5 className="modal-title">Import List</h5>
                    <div className='dialog-close'>
                        <img src={__getImage('/dialog_close.svg')} onClick={() => { closeImportFile() }} />
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='form-group text-left'>
                                <label>Choose File</label>
                                <ImportFileComponent placeholder={"Upload List"} emitDataUpload={handleDataupload} />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>}
            {dialogConfig && <StatusDialog body={dialogConfig.body} isTitle={true} isSuccess={dialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={true} />}
        </>
    </>)
}
export default EnrolmentListing;