import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const CustomCircularProgressBar = ({ value }) => {
    return (
        <>
            <CircularProgressbar
                value={value}
                text={`${value}%`}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    textSize: '18px',
                    pathTransitionDuration: 0.5,
                    pathColor: `rgba(255, 255, 255, ${value / 100})`,
                    textColor: '#fff',
                    trailColor: 'transparent',
                    backgroundColor: '#fff',
                })}
            />
        </>
    )
}
export default CustomCircularProgressBar;