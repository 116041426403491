import React from 'react';
import { __getImage } from '../../../../_factory/globalService';
import style from './index.module.scss';
import CustomButton from '../../../../Components/form/CustomButton';
const CardMatchReport = ({ data }) => {
    return (<div className={`height_100 ${style['match_card']}`}>
        <div className={`height_100 ${style['match_card_inner']}`}>
            <div className='height_100 d-flex justify-content-between'>
                <div className={style['left_cell']}>
                    <img src={__getImage('/matchCardTile.svg')} />
                </div>
                <div className={`d-flex flex-column justify-content-between ${style['right_cell']}`}>
                    <table className={style['match_card_table']}>
                        <thead>
                            <tr>
                                <th>Careers Matched</th>
                                <th>Position</th>
                            </tr>
                        </thead>
                        {data.map((ele, i) => {
                            return (
                                <tr>
                                    <td>
                                        <div className='d-flex'>
                                            <span className={style['icon_diamond']}></span>
                                            <span>
                                                <span className={style['scroed']}>{ele.scored}</span>
                                                /
                                                <span className={style['total']}>{ele.total}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td className={style['position']}>
                                        {ele.position}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                    <div className={style['card-footer']}>
                        <CustomButton text="View Applications" className='btn_next' />
                        <CustomButton text="View Interviews" className='ml-10 btn_transparent' />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default CardMatchReport;