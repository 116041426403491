import React from 'react';
import styles from '../index.module.scss';
import { HeaderLogo } from '../icon';
import { __getImage } from '../../../_factory/globalService';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import customValidations from '../../../_factory/_validation';
import { Field, Form } from 'react-final-form';
import InputText from '../../../Components/form/InputText';
import ErrorMessage from '../../../Components/form/ErrorMessage';
import { _httpSignUp } from '../AuthService';
import PasswordInput from '../../../Components/form/Password';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import CustomButton from '../../../Components/form/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import ChooseAccount from '../../ChooseAccount';
export const RegisterWithType = ({ ...props }) => {
    let navigate = useNavigate();
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    const [initialValue, set_initialValue] = React.useState({})
    const [paramType, set_paramType] = React.useState(null);
    const validate = combineValidators({
        type: composeValidators(isRequired)("Type"),
        name: composeValidators(isRequired)("Name"),
        email: composeValidators(
            isRequired,
            customValidations.isValidEmail()
        )("Email"),
        password: composeValidators(
            isRequired
        )("Password"),
    });
    const onSubmit = async values => {
        let res = await _httpSignUp({ ...values });
        if (res && res.data && res.data.isSuccess) {
            navigate(`/login/${values?.type}`);
            set_initialValue({})
        } else {
            let config = {
                body: 'Something went wrong',
                isSuccess: false,
            }
            set_dialogConfig(config)
        }

    }
    const handleOnClickCallBack = () => {
        set_initialValue({})
        set_dialogConfig(null)
    }
    const on_handleAccountClick = (value) => {
        set_paramType(value)
        let data = {
            ...initialValue,
            type: value
        }
        set_initialValue(data)
    }
    return (
        <>
            {paramType ? <>
                <div className="page_wrapper">
                    <div className={styles.loginBg}>
                        <div className={`${styles.formContainer}`}>
                            <div className={`scrollBar ${styles.innerWrapper}`}>
                                <div className={styles.title}>
                                    <h1 className='text-center'>
                                        <span dangerouslySetInnerHTML={{ __html: HeaderLogo.logo }}></span>
                                    </h1>
                                    <p className={styles.desc}>
                                        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim.
                                    </p>
                                    <div className='form_wrapper'>
                                        <Form
                                            onSubmit={onSubmit}
                                            initialValues={initialValue || {}}
                                            validate={validate}
                                            render={({
                                                handleSubmit,
                                                submitError,
                                                values,
                                                dirtySinceLastSubmit,
                                            }) => (
                                                <form onSubmit={handleSubmit} autoComplete="off">
                                                    <div className="form-group">
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            component={InputText}
                                                            placeholder={`${paramType == 'university' ? 'University ' : ''} Name`}
                                                            leftIcon='User_line.png'
                                                            isAddon={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            component={InputText}
                                                            placeholder="Email"
                                                            leftIcon='User_line.png'
                                                            isAddon={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <Field
                                                            name="password"
                                                            component={PasswordInput}
                                                            placeholder="Password"
                                                            leftIcon='sms.png'
                                                            isAddon={true}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className=" text-right">
                                                            {submitError && !dirtySinceLastSubmit && (
                                                                <ErrorMessage
                                                                    error={submitError}
                                                                    text="Error during submit your form."
                                                                />
                                                            )}
                                                            <div className='form-group'>
                                                                <CustomButton type={'submit'} text='Register' className='btn_next type2 width_100' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        />
                                        <div className='form-group text-center'>
                                            <div className={styles.login_altr}>
                                                Already have an account ? &nbsp;
                                                <Link to={`/login`}>
                                                    <a className={styles.link}>Sign in</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {dialogConfig && <StatusDialog body={dialogConfig.body} isTitle={true} isSuccess={dialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={true} />}
            </> : <ChooseAccount handleAccountClick={on_handleAccountClick} />}
        </>
    )
}
export default RegisterWithType;