import React from 'react';
import styles from './dashboard.module.scss';
import StackedBarChart from './components/StackedBarChart';
import DoughnutChart from './components/DoughnutChart';
import { DashboardData } from './data';
import CardMatchReport from './components/DashboardCard/CardMatchReport';
import CardReport from './components/DashboardCard/CardReport';
const Dashboard = () => {
    return (
        <>
            <div className={`row row-same-height ${styles['graph_sep']}`}>
                <div className='col-same-height col-sm-8'>
                    <div className='content'>
                        <CardMatchReport data={DashboardData?.matcharr} />
                    </div>
                </div>
                <div className='col-same-height col-sm-4'>
                    <div className='content d-flex justify-content-between flex-column'>
                        {DashboardData.cards.map((item, i) => {
                            return (
                                <div className={i!=0?'mt-20':''} key={1}>
                                    <CardReport data={item} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='col-sm-12'>
                        <div className='row form-group'>
                            <div className='col-sm-4'>
                                <DoughnutChart />
                            </div>
                            <div className='col-sm-8'>
                                <StackedBarChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;