export const JobCreateField = {
    "id": '',
    "title": "",
    "responsbilities": "",
    "qualifications": "",
    "industryId": "",
    "employeeTypeId": "",
    "salary": "",
    "minSalary": "",
    "maxSalary": "",
    "location": ""
}