import React from 'react';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import styles from './index.module.scss';
import { __getImage } from '../../../_factory/globalService';
import { __httpJobDetail } from '../JobServicec';
const JobPostView = ({ data, onCloseCallback }) => {
    const handleOnClickCallBack = (e) => {
        onCloseCallback(e)
    }
    const [initialValue, set_initialValue] = React.useState(data.formValue);
    return (<>
        <DialogWrapper className={'width_900'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(e) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div>
                    <div className='form_wrapper'>
                        <div className='row row-same-height'>
                            <div className='col-same-height col-sm-12 col-md-4'>
                                <div className='content form-group'>
                                    <label>Job title</label>
                                    <div className='form-control view'>
                                        {initialValue?.title}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-4'>
                                <div className='content form-group'>
                                    <label>Qualifications</label>
                                    <div className='form-control'>
                                        {initialValue?.qualifications}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-4'>
                                <div className='content form-group'>
                                    <label>Industry</label>
                                    <div className='form-control'>
                                        {initialValue?.industry}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-3'>
                                <div className='content form-group'>
                                    <label>Employee type</label>
                                    <div className='form-control'>
                                        {initialValue?.employeeType}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-3'>
                                <div className='content form-group'>
                                    <label>Salary</label>
                                    <div className='form-control'>
                                        {initialValue?.salary}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-6'>
                                <div className='content form-group'>
                                    <label>Location</label>
                                    <div className='form-control'>
                                        {initialValue?.location}
                                    </div>
                                </div>
                            </div>
                            <div className='col-same-height col-sm-12 col-md-12'>
                                <div className='content form-group'>
                                    <label>Job description & responsibilities</label>
                                    <div className='form-control textarea'>
                                        {initialValue?.responsbilities}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DialogWrapper>
    </>)
}
export default JobPostView;