export const DashboardData = {
    cards: [
        {
            name: 'Total Interviews',
            value: '64',
            desc: '+3% monthly growth',
            banner: 'interview_tile.svg'
        },
        {
            name: 'Total Applications',
            value: '4897',
            view: '8908 views',
            desc: '+2% monthly growth',
            banner: 'resume_tile.svg'
        }
    ],
    matcharr: [
        {
            total: '567',
            scored: '46',
            position: 'UI Designer'
        },
        {
            total: '567',
            scored: '46',
            position: 'Java'
        },
        {
            total: '567',
            scored: '46',
            position: 'Node js'
        },
        {
            total: '567',
            scored: '46',
            position: 'React js'
        }
    ]
}