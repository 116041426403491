import React from 'react';
import DialogWrapper from '../../Components/Dialog/DialogWrapper';
import { __getImage } from '../../_factory/globalService';
import ProfileFormUniversity from './ProfileFormUniversity';
import ProfileEmployerForm from './ProfileEmployerForm';
import { __httpProfileGet, __httpUpdateProfile } from './profileService';
import StatusDialog from '../../Components/Dialog/StatusDialog';
import ProfileFormSuperAdmin from './ProfileFormSuperAdmin';
import { _ProfileAction } from '../../_store/Profile/profile.action';
import { connect } from 'react-redux';
import ProfileFormAdmin from './ProfileFormAdmin';
const ProfileComponent = ({ data, userData, onCloseCallback, ...props }) => {
    const [formValues, set_formValues] = React.useState(null)
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    const handleOnClickCallBack = (e) => {
        onCloseCallback(e)
    }
    React.useEffect(() => {
        getUserDetail(false)
    }, [])
    const getUserDetail = (isEditProfile) => {
        let id = userData?.id;
        __httpProfileGet(id).then(res => {
            if (res.data.isSuccess) {
                let responseData = {
                    ...res.data.responseData,
                }
                if (isEditProfile) {
                    props.dispatch(_ProfileAction({ ...responseData }));
                }
                set_formValues(responseData)
            } else {
                set_formValues(data)
            }
        })
    }
    const handle_submitCallback = (value) => {
        __httpUpdateProfile(value).then(res => {
            if (res?.data?.isSuccess) {
                let config = {
                    body: res.data.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                }
                getUserDetail(true)
                set_dialogConfig(config)
                setTimeout(() => {
                    set_dialogConfig(null)
                    handleOnClickCallBack(null)
                }, 3000)
            } else {
                let config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                }
                set_dialogConfig(config)
            }

        })
    }
    const handle_uploadFileCallBack = (res) => {
        let config = null
        if (res?.data?.isSuccess) {
            config = {
                body: res.data.responseMessage || 'User Data saved successfully',
                isSuccess: true,
            }
            getUserDetail(true)
        } else {
            config = {
                body: 'Something went wrong',
                isSuccess: false,
            }
        }
        set_dialogConfig(config)
        setTimeout(() => {
            set_dialogConfig(null)
        }, 3000)
    }
    const renderProfileForm = (data) => {
        if (data.type == 'university') {
            return <ProfileFormUniversity formValues={formValues} submitCallback={handle_submitCallback} uploadFileCallBack={handle_uploadFileCallBack} />
        } else if (data.type == 'employer') {
            return <ProfileEmployerForm formValues={formValues} submitCallback={handle_submitCallback} uploadFileCallBack={handle_uploadFileCallBack} />
        } else if (data.type == 'superadmin') {
            return <ProfileFormSuperAdmin formValues={formValues} submitCallback={handle_submitCallback} uploadFileCallBack={handle_uploadFileCallBack} />
        } else {
            return <ProfileFormAdmin formValues={formValues} submitCallback={handle_submitCallback} uploadFileCallBack={handle_uploadFileCallBack} />
        }
    }
    return (<>
        <DialogWrapper className={'width_900'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data?.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div className='form_wrapper'>
                    {renderProfileForm(data)}
                </div>
            </div>
        </DialogWrapper>
        {dialogConfig && <StatusDialog body={dialogConfig.body} isTitle={true} isSuccess={dialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={true} />}
    </>)
}
export default connect(null, null)(ProfileComponent);
