import React from 'react';
import qs from './question.module.scss';
import { __getImage } from '../../_factory/globalService';
import { __getMasterList } from '../../_factory/masterService';
import { __httpSaveQuestion } from './QuestionService';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import QuestinFormAddWrapper from './QuestinFormAddWrapper';
import QuestinDetailWrapper from './QuestinDetailWrapper';
let initialForm = {
    "id": "",
    "categoryId": "",
    "category": "",
    "subCategory": "",
    "carrierId": "",
    "carrier": "",
    "timelimit": "",
    "answerType": '',
    "question": "",
    "answers": [
        {
            "answer": "",
            "weightage": "",
            "tag": ""
        }
    ]
}
const QuestinFormWrapper = ({ ...props }) => {
    const params = useParams();
    const { _QuestionDetailReducer = null } = props;
    const [categoryMaster, set_categoryMaster] = React.useState([]);
    const [subCategoryMaster, set_subCategoryMaster] = React.useState([]);
    const [carrierMaster, set_carrierMaster] = React.useState([]);
    const [savedQuestion, set_savedQuestion] = React.useState('');
    const [isDataloaded, set_isDataloaded] = React.useState(false)
    React.useEffect(() => {
        get_category('category');
        get_carrier('career');
    }, []);
    React.useEffect(() => {
        if (params && params.id == 0) {
            set_isDataloaded(true)
        }
    }, []);


    React.useEffect(() => {
        if ((_QuestionDetailReducer && _QuestionDetailReducer.categoryId && _QuestionDetailReducer.categoryId.length > 0) && categoryMaster && categoryMaster.length > 0) {
            create_subCategoryMaster(_QuestionDetailReducer.categoryId)
        }
    }, [_QuestionDetailReducer, categoryMaster])

    const create_subCategoryMaster = (id) => {
        var index = categoryMaster.map((category) => { return category.id; }).indexOf(id);
        if (index >= 0) {
            if (categoryMaster[index]) {
                let temp = categoryMaster[index] && categoryMaster[index]['subItems'];
                set_subCategoryMaster(temp || [])
            }
        }
    }
    const get_category = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_categoryMaster(responseData)
    }

    const get_carrier = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_carrierMaster(responseData)
    }

    return (
        <>
            <div className='page_wrapper'>
                <div className='container'>
                    <div className={qs['question_inner']}>
                        <h1 className={qs['title']}>Question Management</h1>
                        {_QuestionDetailReducer ? <>
                            {_QuestionDetailReducer?.type == 2 ? <QuestinDetailWrapper qs={qs} _QuestionDetailReducer={_QuestionDetailReducer} initialForm={initialForm} subCategoryMaster={subCategoryMaster} /> : <QuestinFormAddWrapper qs={qs} _QuestionDetailReducer={_QuestionDetailReducer} initialForm={initialForm} subCategoryMaster={subCategoryMaster} />}
                        </> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    const { _QuestionDetailReducer } = state.question;
    return {
        _QuestionDetailReducer,
    }
}
export default connect(mapStateToProps, null)(QuestinFormWrapper);