import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __ExportToExcel, __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { Link } from "react-router-dom";
import { __httpJobList } from '../JobServicec';
import JobPostForm from '../JobPostForm/JobPostForm';
import { JobCreateField } from '../../../_modal/JobModal';
import JobPostView from '../JobPostForm/JobPostView';
import { __httpAllUserList } from '../../../_factory/masterService';
import { AutoComplete } from "primereact/autocomplete";
import Paging from '../../../Components/Paging';
import TableSearchComponent from '../../../Components/TableSearch';
const JobListingComponent = () => {
    const [listData, set_listData] = React.useState(null);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [formDialogConfig, set_formDialogConfig] = React.useState(null);
    const [formViewDialogConfig, set_formViewDialogConfig] = React.useState(null);
    /* auto complete */
    const [userList, set_userList] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [filteredUserData, setFilteredUserData] = React.useState(null);
    /*  */
    const [searchParam, set_searchParam] = React.useState({});
    const [type, set_type] = React.useState(null);
    const [loginType, set_loginType] = React.useState(null);
    const [searchTitle, set_searchTitle] = React.useState('Search');

    /* paging */
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [searchInput, set_searchInput] = React.useState('');
    React.useEffect(() => {
        let userData = __getLoginDetail();
        if (userData?.type == 'superadmin') {
            set_searchTitle('Filter by employer wise')
            set_type('employer');
            set_loginType(userData?.type)
            getAllUser('employer')
        } else {
            getListingData(searchParam);
        }
    }, []);

    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])
    React.useEffect(() => {
        filterListingData(searchInput)
    }, [listData]);

    const getAllUser = (searchUserType) => {
        let payload = {
            "name": "",
            "type": searchUserType
        }
        __httpAllUserList(payload).then(res => {
            if (res.data.isSuccess) {
                let responseData = res.data.responseData;
                set_userList(responseData)
                set_isDataLoaded(true)
            }
        })
    }
    const getListingData = (body) => {
        let temp = []
        __httpJobList(body).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                temp = res.data.responseData;
            } else {
                temp = [];
            }
            set_listData(temp)
            set_isDataLoaded(true)
        })
    }
    const handleSearch = (value) => {
        set_searchInput(value);
        filterListingData(value)
    }
    const filterListingData = (val = '') => {
        let filteredData = [];
        if (val === "") {
            filteredData = listData;
        } else {
            filteredData = listData.filter((item) => {
                let name = item.title.toLowerCase()
                return name.includes(val.toLowerCase());
            });
        }
        set_filteredListData(filteredData)
    }
    const openJobView = (data) => {
        let temp = {
            isOpen: true,
            isReload: true,
            title: 'View Job Detail',
            formValue: {
                ...data
            }
        }
        set_formViewDialogConfig(temp)
    }
    const openJobForm = (data) => {
        let fieldValue = {
            ...JobCreateField
        }
        if (data) {
            for (const key in fieldValue) {
                if (data.hasOwnProperty(key)) {
                    fieldValue[key] = data[key]
                }
            }
            fieldValue.id = data?.id;
        }

        let temp = {
            isOpen: true,
            isReload: true,
            title: 'Add Job',
            formValue: {
                ...fieldValue
            }
        }
        set_formDialogConfig(temp)
    }
    const handleOnCloseCallback = (res) => {
        if (res) {
            set_formDialogConfig(res)
            if (res.isReload) {
                getListingData()
            }
        } else {
            set_formDialogConfig(null)
        }

    }
    const handleOnCloseViewCallback = () => {
        set_formViewDialogConfig(null)
    }
    const search = (event) => {
        setTimeout(() => {
            let _filteredUserData;
            if (!event.query.trim().length) {
                _filteredUserData = [...userList];
            }
            else {
                _filteredUserData = userList.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUserData(_filteredUserData);
        }, 250);
    }
    const handleAutoCompleteChange = (e) => {
        setSelectedUser(e.value)

    }
    const handleONSelect = (e) => {
        let param = {
            employerId: e.value.id || ''
        }
        set_searchParam(param)
        getListingData(param);
    }

    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const ExportToExcel = (type, id, name) => {
        __ExportToExcel(type, id, name)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className='row plr-15'>
                    {loginType == 'superadmin' ? <div className='col-md-4 col-sm-12'>
                        <div className='form-group'>
                            <div className='search_bar'>
                                <div className='custom-autocomplete width_icon search_inner'>
                                    <img className={`search_icon ${style['search_icon']}`} src={__getImage('search_icon.svg')} />
                                    <AutoComplete field="name" value={selectedUser} suggestions={filteredUserData} completeMethod={search} onChange={(e) => handleAutoCompleteChange(e)} placeholder={searchTitle} onSelect={(e) => { handleONSelect(e) }} emptyMessage="No results" />
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                    <div className='col-md-4 col-sm-12'>
                        <div className='form-group'>
                            <TableSearchComponent placeholder='Search by name' searchInput={searchInput} handleSearch={handleSearch} />
                        </div>
                    </div>
                </div>
                {/* <DownloadExcel/> */}
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            List of Job's
                        </div>
                        {isDataLoaded && listData && <div className={style['count']}>{listData.length} Record{listData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`}>
                            <img src={__getImage('filtersearch.svg')} />
                            Filter
                        </button>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { ExportToExcel('xlsx', '_idJobListTable', 'Job Listing') }}>
                            <img src={__getImage('exportsquare.svg')} />
                            Export
                        </button>
                        {loginType !== 'superadmin' && <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { openJobForm(null) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>}

                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="_idJobListTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content'>
                                        Job title
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Qualifications
                                    </div>
                                </th>
                                <th>

                                    <div className='content'>
                                        Industry
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Employee type
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Salary
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Location
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Responsibilities
                                    </div>
                                </th>
                                <th>
                                    <div className='text-center content'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {filteredListData && filteredListData.length > 0 ? <>
                                    {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td>
                                                    <Link href="/job/post/view/1">
                                                        <div className='d-flex'>
                                                            <div className='content'>
                                                                {ele.title}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.qualifications}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.industry}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.employeeType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>

                                                        {ele.minSalary}  {ele.maxSalary ? 'to' : '-'}  {ele.maxSalary}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.location}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele.responsbilities}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        {loginType !== 'superadmin' ? <div className='mr-10 pointer content' onClick={() => { openJobForm(ele) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div> : ''}

                                                        <div className='pointer content' onClick={() => { openJobView(ele) }}>
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {formDialogConfig && formDialogConfig.isOpen && <JobPostForm data={formDialogConfig} onCloseCallback={handleOnCloseCallback} />}
        {formViewDialogConfig && formViewDialogConfig.isOpen && <JobPostView data={formViewDialogConfig} onCloseCallback={handleOnCloseViewCallback} />}
    </>)
}
export default JobListingComponent; 