import React from 'react';
import Card from '../../Components/Card';
import { __getImage } from '../../_factory/globalService';
import { __getMasterList } from '../../_factory/masterService';
import { __httpSaveQuestion } from './QuestionService';
import { useParams } from 'react-router-dom';
const QuestinDetailWrapper = ({ qs, _QuestionDetailReducer = {}, initialForm = {}, ...props }) => {
    const [initialValue, set_initialValue] = React.useState({});
    const [categoryMaster, set_categoryMaster] = React.useState([]);
    const [tagMaster, set_tagMaster] = React.useState([]);
    const [carrierMaster, set_carrierMaster] = React.useState([]);
    React.useEffect(() => {
        get_category('category');
        get_carrier('career');
    }, []);
    React.useEffect(() => {
        if (_QuestionDetailReducer) {
            setFormInitialValue()
        }
    }, [_QuestionDetailReducer]);
    const setFormInitialValue = () => {
        let temp = {
            ...initialForm
        }
        for (const key in initialForm) {
            const hasKey = _QuestionDetailReducer.hasOwnProperty(key);
            if (hasKey && _QuestionDetailReducer[key]) {
                temp[key] = _QuestionDetailReducer[key]
            }
        }
        set_initialValue(temp);
    }
    React.useEffect(() => {
        if ((_QuestionDetailReducer && _QuestionDetailReducer.categoryId && _QuestionDetailReducer.categoryId.length > 0) && categoryMaster && categoryMaster.length > 0) {
            createTagMaster(_QuestionDetailReducer.categoryId)
        }
    }, [_QuestionDetailReducer, categoryMaster])

    const createTagMaster = (id) => {
        var index = categoryMaster.map((category) => { return category.id; }).indexOf(id);
        if (index >= 0) {
            if (categoryMaster[index]) {
                let temp = categoryMaster[index] && categoryMaster[index]['subItems'];
                set_tagMaster(temp || [])
            }
        }
    }
    const get_category = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_categoryMaster(responseData)
    }

    const get_carrier = async (type) => {
        let body = {
            "type": type
        }
        let res = await __getMasterList(body);
        let responseData = res?.data?.responseData || [];
        set_carrierMaster(responseData)
    }

    return (
        <Card>
            <div className={qs['card_wrapper_inner']}>
                <div className='form_wrapper'>
                    <div className='row row-same-height'>
                        <div className='col-same-height col-sm-12 col-md-4'>
                            <div className='content form-group'>
                                <label>Category</label>
                                <div className='form-control view'>
                                    {initialValue?.category}
                                </div>
                            </div>
                        </div>
                        <div className='col-same-height col-sm-12 col-md-4'>
                            <div className='content form-group'>
                                <label>Sub Category</label>
                                <div className='form-control view'>
                                    {initialValue?.subCategory}
                                </div>
                            </div>
                        </div>
                        <div className='col-same-height col-sm-12 col-md-4'>
                            <div className='content form-group'>
                                <label>Carrier</label>
                                <div className='form-control view'>
                                    {initialValue?.carrier}
                                </div>
                            </div>
                        </div>
                        <div className='col-same-height col-sm-12 col-md-4'>
                            <div className='content form-group'>
                                <label>Time Limit</label>
                                <div className='form-control view'>
                                    {initialValue?.timelimit}
                                </div>
                            </div>
                        </div>
                        <div className='col-same-height col-sm-12 col-md-4'>
                            <div className='content form-group'>
                                <label>Answer Type</label>
                                <div className='form-control view'>
                                    {initialValue?.answerType}
                                </div>
                            </div>
                        </div>
                        <div className='col-same-height col-sm-12 col-md-6'>
                            <div className='content form-group'>
                                <label>Question</label>
                                <div className='form-control textarea view'>
                                    <span dangerouslySetInnerHTML={{ __html: initialValue?.question }}></span>
                                </div>
                            </div>
                        </div>
                        {initialValue && initialValue.answers && initialValue.answers.length > 0 ? <>
                            {initialValue.answers.map((item, index) => {
                                return (
                                    <>
                                        <div className={`width_100 ${index != 0 ? 'mt-20' : ''}`} key={`ans_${index}`}>
                                            <Card>
                                                <div className='row' style={{ 'padding': '0 20px' }}>
                                                    <div className='col-sm-8'>
                                                        <div className='content form-group'>
                                                            <label>Answer Type</label>
                                                            <div className='form-control textarea view'>
                                                                <span dangerouslySetInnerHTML={{ __html: initialValue?.question }}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='content form-group'>
                                                            <label>Weightage</label>
                                                            <div className='form-control view'>
                                                                {item?.weightage}
                                                            </div>
                                                        </div>
                                                        <div className='content form-group'>
                                                            <label>Tag</label>
                                                            <div className='form-control view'>
                                                                {item?.tag}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </>
                                )
                            })}

                        </> : ''}
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default QuestinDetailWrapper;