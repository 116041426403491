export const StaticData = {
    title: 'Choose Account Type',
    accountType: [
        {
            type: 'University',
            img: `account/University.svg`,
            urlFragment: 'university'
        },
        {
            type: 'Employer',
            img: `account/Employer.svg`,
            urlFragment: 'employer'
        }
    ],
}