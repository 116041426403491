import { useNavigate} from 'react-router-dom';
import { __localStorageGet } from '../_factory/globalService';
const PrivateHook = ({ authGuard, children }) => {
    const userData = __localStorageGet('userDataTM');
    const _jwt = userData && userData.token;
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/choose-account')
    }
    if (authGuard && !_jwt) {
        return <>
            {handleRedirect()}
        </>
    } else return (
        <>
            <div>
                {children}
            </div>
        </>
    )
}

export default PrivateHook;