import React from 'react';
import { __getImage } from '../../../../_factory/globalService';
import style from './index.module.scss';
const CardReport = ({ data }) => {
    return (<div className={style['match_card_small']}>
        <div className='d-flex'>
            <div className={style['score_i']}>
                <img src={__getImage(data?.banner)} />
            </div>
            <div className={style['card_score']}>
                {data?.name ? <p className={style['text_1']}>{data?.name}</p> : ''}
                {data?.value ? <p className={style['text_2']}>{data?.value}</p> : ''}
                {data?.view ? <p className={style['text_4']}>{data?.view}</p> : ''}
                {data?.desc ? <p className={style['text_3']}>{data?.desc}</p> : ''}
            </div>
        </div>
    </div>)
}
export default CardReport;