import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { __httpDeleteQuestion, __httpQuestionList } from '../QuestionService';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import ConfirmDialog from '../../../Components/Dialog/ConfirmDialog';
import { _QuestionAction } from '../../../_store/Question/question.action';
import { connect } from 'react-redux';
import Paging from '../../../Components/Paging';
const QuestionListComponent = ({ ...props }) => {
    const params = useParams();
    let navigate = useNavigate();
    const [listData, set_listData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    const [confirmDialogConfig, set_confirmDialogConfig] = React.useState(null);
    const [selectedId, set_selectedId] = React.useState(null);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);

    React.useEffect(() => {
        getListingData();
    }, [params])
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])
    const getListingData = () => {
        __httpQuestionList().then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                set_listData(res.data.responseData)
            } else {
                set_listData(null)
            }
            set_isDataLoaded(true)
        })
    }
    const handleQuestionForm = (obj, type) => {
        let config = {}
        let url;
        if (obj) {
            obj.id = obj.id;
            config = {
                ...obj
            }
            url = `/question/form/${obj.id}`
        } else {
            url = `/question/form/0`
        }
        config.type = type;
        props.dispatch(_QuestionAction({ ...config }));
        navigate(url);
    }
    const handle_onYesClickCallBack = () => {
        let id = selectedId;
        let postDto = {
            "id": id,
            "categoryId": "-1"
        }
        __httpDeleteQuestion(postDto).then(res => {
            set_confirmDialogConfig(null)
            let config = {}
            if (res && res.data && res.data.isSuccess) {
                config = {
                    isOpen: true,
                    body: res.data.responseMessage || 'User Data saved successfully',
                    isSuccess: true,
                }
                set_dialogConfig(config)
                setTimeout(() => {
                    set_dialogConfig(null);
                    getListingData()
                }, 1000)
            } else {
                config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                }
                set_dialogConfig(config)
                setTimeout(() => {
                    set_dialogConfig(null)
                }, 2000)
            }
            set_selectedId(null)
        })
    }
    const handle_onNoClickCallBack = () => {
        set_confirmDialogConfig(null)
    }
    const handleDeleteRecord = (id) => {
        let cDC = {
            isOpen: true,
            title: 'Message',
            body: 'Are you sure you want to delete ?'
        }
        set_selectedId(id)
        set_confirmDialogConfig(cDC)
    }
    const handleOnClickCallBack = () => {
        set_dialogConfig(null)
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            Questions
                        </div>
                        {isDataLoaded && listData && <div className={style['count']}>{listData.length} Record{listData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`} onClick={() => { handleQuestionForm(null, 0) }}>
                            <img src={__getImage('exportsquare.svg')} />
                            New
                        </button>
                    </div>
                </div>
                <div className='table_wrapper scrollBar'>
                    <table id="saleReportTable">
                        <thead>
                            <tr>
                                <th>
                                    <div className='content'>
                                        Question
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Category
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Sub Catgory
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Time Limit
                                    </div>
                                </th>
                                <th>
                                    <div className='content text-center'>
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {listData && listData.length > 0 ? <>
                                    {listData && listData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`en_${i}`}>
                                                <td>
                                                    <div className={`content ${style['ques_cell']}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: ele?.question || 'NA' }} ></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.category || 'NA'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.subCategory || 'NA'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.timelimit || 'NA'}
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='mr-10 pointer content' onClick={() => { handleQuestionForm(ele, 2) }}>
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='mr-10 pointer content' onClick={() => { handleQuestionForm(ele, 1) }}>
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </div>
                                                        <div className='pointer content' onClick={() => { handleDeleteRecord(ele?.id) }}>
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && listData && listData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={listData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {dialogConfig && dialogConfig?.isOpen && <StatusDialog body={dialogConfig.body} isTitle={true} isSuccess={dialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={false} />}
        {confirmDialogConfig && confirmDialogConfig.isOpen ? <ConfirmDialog body={confirmDialogConfig?.body} isTitle={true} title={confirmDialogConfig?.title} onYesClickCallBack={handle_onYesClickCallBack} onNoClickCallBack={handle_onNoClickCallBack} /> : ''}
    </>)
}
export default connect(null, null)(QuestionListComponent);