import React from 'react';
import styles from '../index.module.scss';
import { HeaderLogo } from '../icon';
import { __getImage, __localStorageSet } from '../../../_factory/globalService';
import { Field, Form } from 'react-final-form';
import InputText from '../../../Components/form/InputText';
import ErrorMessage from '../../../Components/form/ErrorMessage';
import { combineValidators, composeValidators, isRequired } from 'revalidate';
import customValidations from '../../../_factory/_validation';
import { _httpLogin } from '../AuthService';
import PasswordInput from '../../../Components/form/Password';
import StatusDialog from '../../../Components/Dialog/StatusDialog';
import CustomButton from '../../../Components/form/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { routerConfig } from '../../../_factory/routerConfig';
import { __getLoginDetail } from '../../../_factory/globalService';
import { __httpProfileGet } from '../../Profile/profileService';
export const LoginWithType = ({ ...props }) => {
    let navigate = useNavigate();
    const [initialValue, set_initialValue] = React.useState({});
    const [dialogConfig, set_dialogConfig] = React.useState(null);
    const [isLoggedInCheck, set_isLoggedInCheck] = React.useState(null);
    const validate = combineValidators({
        email: composeValidators(
            isRequired,
            customValidations.isValidEmail()
        )("Email"),
        password: composeValidators(
            isRequired
        )("Password")
    });

    React.useEffect(() => {
        validateUser()
    }, [])
    const validateUser = () => {
        const userData = __getLoginDetail();
        if (userData) {
            let url = routerConfig.dashboard.route.replace(':type', userData?.type)
            navigate(url);
            set_isLoggedInCheck(true)
        } else {
            set_isLoggedInCheck(true)
        }
    }

    const onSubmit = async values => {
        let res = await _httpLogin({ ...values });
        if (res && res.data && res.data.isSuccess) {
            let userData = {}
            if (res.data.responseData) {
                const responseData = res.data.responseData;
                let profileInfo = {}
                __httpProfileGet(responseData.id).then(res => {
                    profileInfo = res.data.responseData;
                    userData = {
                        ...profileInfo,
                        token: 'yes',
                    }
                    __localStorageSet('userDataTM', userData);
                    setTimeout(() => {
                        let url = routerConfig.dashboard.route.replace(':type', profileInfo?.type)
                        navigate(url);
                    }, 500)
                })
            }
        } else {
            let config = {
                body: 'Something went wrong',
                isSuccess: false,
            }
            set_dialogConfig(config)
        }

    }
    const handleOnClickCallBack = () => {
        set_initialValue({})
        set_dialogConfig(null)
    }

    return (
        <>
            <div className="page_wrapper">
                <div className={`${styles.loginBg}`}>
                    <div className={`${styles.formContainer}`}>
                        <div className={`scrollBar ${styles.innerWrapper}`}>
                            <div className={styles.title}>
                                <h1 className='text-center'>
                                    <span dangerouslySetInnerHTML={{ __html: HeaderLogo.logo }}></span>
                                </h1>
                                <p className={styles.desc}>
                                    consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim.
                                </p>
                                <div className='form_wrapper'>
                                    <Form
                                        onSubmit={onSubmit}
                                        initialValues={initialValue || {}}
                                        validate={validate}
                                        render={({
                                            handleSubmit,
                                            submitError,
                                            values,
                                            dirtySinceLastSubmit,
                                        }) => (
                                            <form onSubmit={handleSubmit} autoComplete="off">
                                                <div className="form-group">
                                                    <Field
                                                        type="text"
                                                        name="email"
                                                        component={InputText}
                                                        placeholder="Email"
                                                        leftIcon='User_line.png'
                                                        isAddon={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        name="password"
                                                        component={PasswordInput}
                                                        placeholder="Password"
                                                        leftIcon='sms.png'
                                                        isAddon={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div className=" text-right">
                                                        {submitError && !dirtySinceLastSubmit && (
                                                            <ErrorMessage
                                                                error={submitError}
                                                                text="Error during submit your form."
                                                            />
                                                        )}
                                                        <div className='form-group'>
                                                            <CustomButton type={'submit'} text='Login' className='btn_next type2 width_100' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                    <div className='form-group text-center'>
                                        <div className={styles.login_altr}>
                                            Don't have an account ? &nbsp;
                                            <Link to={'/register'}>
                                                <a className={styles.link}>Sign up</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dialogConfig && <StatusDialog body={dialogConfig.body} isTitle={true} isSuccess={dialogConfig.isSuccess} onClickCallBack={handleOnClickCallBack} footer={true} />}
        </>
    )
}
export default LoginWithType;