import React from 'react';
import style from './index.module.scss';
import CustomButton from '../../../Components/form/CustomButton';
import { ProgressBar } from 'primereact/progressbar';
import { Carousel } from 'primereact/carousel';
import { __getImage } from '../../../_factory/globalService';
import { StudentDetailData } from './data';
import CarrierMatchTpl from './CarrierMatchTpl';
import CustomCircularProgressBar from '../../../Components/Chart/CustomCircularProgressBar';
import { __httpEnrolmentDetail } from '../enrolmentService';
import { useParams } from 'react-router-dom';
const EnrolmentDetail = ({ ...props }) => {
    const params = useParams();
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [pCompletion, set_pCompletion] = React.useState(StudentDetailData['profileCompletion']);
    const [assessmentArr, set_assessmentArr] = React.useState(StudentDetailData['assessmentArr']);
    const [careersMatchArr, set_careersMatchArr] = React.useState(StudentDetailData.careersMatchArr);
    const [detail, set_detail] = React.useState(null);
    React.useEffect(() => {
        if (params && params.studentId.length > 1) {
            getDetail(params.studentId)
        }
    }, []);
    const getDetail = (id) => {
        __httpEnrolmentDetail(id).then(res => {
            set_detail(res && res.data && res.data.responseData)
            set_isDataLoaded(true)
        })
    }
    return (<>
        {isDataLoaded ? <>
            {detail ? <>
                <div className={`row ${style['top_bar']}`}>
                    <div className='col-sm-12 col-md-6'>
                        <div className='d-flex'>
                            <div style={{ width: 120, height: 120 }}>
                                <CustomCircularProgressBar value={78} />
                            </div>
                            <div className={`ml-10 d-flex align-items-center`}>
                                <img src={__getImage('User profil.svg')} />
                                <div className='ml-10'>
                                    <p className={style['user_name']}>{detail?.name}</p>
                                    <p className={style['user_un']}>{detail?.department}</p>
                                    <p className={style['user_des']}>{detail?.position}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <div className='d-flex align-items-center'>
                            <div className={style['total_progress']}>
                                <div className={style['heading']}>Progress</div>
                                <ProgressBar value={pCompletion}></ProgressBar>
                                <div className={style['progress_text']}>
                                    +5% monthly progress
                                </div>
                            </div>
                            <div className={`d-flex flex-column ${style['action_cell']}`}>
                                <CustomButton text="Applied Jobs" className='ml-10 mb-10 btn_next' />
                                <CustomButton text="View Reports" className='ml-10 mt-10 btn_transparent' />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={style['completion_bar']}>
                        <div className={style['title']}>Profile completion</div>
                        <ProgressBar value={pCompletion}></ProgressBar>
                    </div>
                </div>
                <div className={`row-same-height ${style['skill_assesment']}`}>
                    <div className='col-same-height col-sm-12 col-md-8'>
                        <div className='content'>
                            <div className={`${style['skill_resume']}`}>
                                <div className='d-flex align-items-center'>
                                    <div className={style['heading-text']}>Skills</div>
                                    <CustomButton iconLeft='/icon_download.svg' type={'button'} text='Resume' className='btn_next' />
                                </div>
                                <div className={`d-flex flex-wrap ${style['skills']}`}>
                                    {detail && detail.skills && detail.skills.length > 0 ? detail && detail.skills && detail.skills.map((item, i) => {
                                        return (
                                            <div key={`skil_${i}`} className={style['skill']}>
                                                <div className={style['inner']}>
                                                    {item}
                                                </div>
                                            </div>
                                        )
                                    }) : <>
                                        <div key={`int_0`}>No Records...</div>
                                    </>}
                                </div>
                            </div>
                            <div className={style['interest_wrap']}>
                                <div className={style['heading-text']}>
                                    Interests
                                </div>
                                <div className={`d-flex ${style['intrest_items']}`}>
                                    {detail && detail.interests && detail.interests.length > 0 ? <>
                                        {detail.interests.map((ele, i) => {
                                            return (<div key={`int_${i}`} className={style['intrest_item']}>{ele}</div>)
                                        })}
                                    </> : <>
                                        <div key={`int_0`}>No Records...</div>
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-same-height col-sm-12 col-md-4'>
                        <div className='content'>
                            <div className={style['heading-text']}>360° Assessment</div>
                            <div className={style['assessment_list']}>
                                <ul>
                                    {assessmentArr.map((item, index) => {
                                        return (
                                            <li className={style['item']}>
                                                <div className='d-flex justify-content-between'>
                                                    <span className={style['text1']}>{item.text}</span>
                                                    <span className={style['value1']}>{`${item.value} %`}</span>
                                                </div>
                                                <ProgressBar className='progress-bar-slim no-text' value={item.value}></ProgressBar>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Careers matched */}
                <div className={style['careers_match']}>
                    <div className={style['heading-text']}>Careers matched</div>
                    <Carousel value={careersMatchArr} itemTemplate={CarrierMatchTpl} showNavigators={true} showIndicators={false} numScroll={1} numVisible={2} />
                </div>
            </> : 'No Records'}
        </> : 'Loading'}
    </>)
}
export default EnrolmentDetail;