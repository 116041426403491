import React from 'react';
import { Field, Form } from 'react-final-form';
import { __getImage, __getImageurl } from '../../_factory/globalService';
import InputText from '../../Components/form/InputText';
import ErrorMessage from '../../Components/form/ErrorMessage';
import CustomButton from '../../Components/form/CustomButton';
import PasswordInput from '../../Components/form/Password';
import InputFile from '../../Components/form/InputFile';
import { __httpProfilePicUpdate } from './profileService';
//import ReactJsonView from '../../Components/ReactJsonView';
const ProfileFormAdmin = ({ formValues, submitCallback, uploadFileCallBack }) => {
    const [initialValue, set_initialValue] = React.useState({});
    const [file, set_file] = React.useState([]);
    React.useEffect(() => {
        let data = {
            id: formValues?.id || '',
            name: formValues?.name || '',
            email: formValues?.email || '',
            password: formValues?.password || '',
            type: formValues?.type || '',
            profilePic: formValues?.profilePic || ''
        }
        set_initialValue(data)
        if (formValues?.profilePic) {
            let temp = [];
            let url = __getImageurl('user_profile', formValues?.profilePic)
            temp.push(url);
            set_file(temp)
        }

    }, [formValues])
    const onSubmit = async (values) => {
        let data = {
            id: values?.id || '',
            name: values?.name || '',
            password: values?.password || ''
        }
        submitCallback(data)
    }
    const handleUploadFileCallback = (value) => {
        __httpProfilePicUpdate(value.event, formValues?.id).then(res => {
            uploadFileCallBack(res)
        })

    }
    return (<>
        {formValues?.id && formValues?.id.length > 0 ? <div className='form-group'>
            <InputFile placeholder='Upload Profile Image' file={file} uploadFileCallback={handleUploadFileCallback} />
        </div> : ''}
        <Form
            onSubmit={onSubmit}
            initialValues={initialValue || {}}
            render={({
                handleSubmit,
                submitError,
                values,
                dirtySinceLastSubmit,
            }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            <div className="form-group">
                                <Field
                                    type="text"
                                    name="email"
                                    component={InputText}
                                    placeholder="Email"
                                    isDisabled={true}
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <div className="form-group">
                                <Field
                                    type="text"
                                    name="name"
                                    component={InputText}
                                    placeholder="User Name"
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <div className="form-group">
                                <Field
                                    type="text"
                                    name="password"
                                    component={PasswordInput}
                                    placeholder="Password"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className=" text-right">
                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage
                                    error={submitError}
                                    text="Error during submit your form."
                                />
                            )}
                            <div className='form-group txt-center'>
                                <CustomButton type={'submit'} text='Submit' className='btn_next' />
                            </div>
                        </div>
                    </div>
                </form>
            )}
        />
    </>)
}
export default ProfileFormAdmin;