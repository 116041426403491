import { apiConfig } from '../../_factory/apiConfig';
import { HttpFetch, HttpPost } from '../../_factory/http-common';
export const __httpEnrolmentList = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.student.list;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}


export const __httpEnrolmentDetail = async (id, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.student.detail.replace('{id}', id);
        const res = await HttpFetch(url, {})
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

