import React from 'react';
import "./index.scss";
const Card = ({ children, ...props }) => {
    return (
        <div className='card_wrapper'>
            <div className='card_wrapper_inner'>
                {children}
            </div>
        </div>
    )
}
export default Card;