const EmployeeMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/employer',
        activeMenuFor: ['/dashboard/employer']
    },
    {
        ds_nm: 'Job Listing',
        url: '/job/listing',
        activeMenuFor: ['/job/listing']
    },
    {
        ds_nm: 'Applied Students List',
        url: '/job/listing/applied',
        activeMenuFor: ['/job/listing/applied']
    },
    {
        ds_nm: 'Assessment',
        url: '',
        activeMenuFor: ['']
    }
]
export default EmployeeMenuData