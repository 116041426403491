const UniversityMenuData = [
    {
        ds_nm: 'Dashboard',
        url: '/dashboard/university',
        activeMenuFor: ['/dashboard/university']
    },
    {
        ds_nm: 'Student Enrolments',
        url: '/enrolment/list/university',
        activeMenuFor: ['/enrolment/list/university']
    },
    {
        ds_nm: 'Applied Jobs',
        url: '/job/listing/applied',
        activeMenuFor: ['/job/listing/applied']
    },
    {
        ds_nm: 'Assessment',
        url: '',
        activeMenuFor: ['']
    }
]
export default UniversityMenuData;