export const routerConfig = {
    register: {
        route: '/register'
    },
    login: {
        route: '/login'
    },
    chooseAccount: {
        route: '/choose-account'
    },
    enrolment: {
        list: {
            route: '/enrolment/list/:type'
        },
        view: {
            route: '/enrolment/student/detail/:studentId'
        }
    },
    dashboard: {
        route: '/dashboard/:type'
    }
};
