import React from 'react';
import Card from '../../../Components/Card';
import style from './carrierTile.module.scss';
import CustomButton from '../../../Components/form/CustomButton';
const CarrierMatchTpl = (product) => {
    return (
        <div className={style['carrier_tile']}>
            <Card>
                <div className={`row-same-height ${style['carrier_match']}`}>
                    <div className={`col-same-height ${style['left_cell']}`}>
                        <div className='content'>
                            <div className={style['name']}>
                                {product.name}
                            </div>
                            <div className={style['detail']}>
                                <div className={style['position']}>{product.position}</div>
                                <div className={style['desc']}>
                                    {product.desc}
                                    <span className={style['more']}>know more</span>
                                </div>
                            </div>
                            <div className={style['salary_range']}>{product.salaryRange}</div>
                        </div>
                    </div>
                    <div className={`col-same-height ${style['right_cell']}`}>
                        <div className='content'>
                            <div className={style['popular_card']}>
                                <div className={style['card_inner']}>
                                    <span className={style['score']}>{product.score}%</span>
                                    <span className={style['match_text']}>{product.match}</span>
                                </div>
                            </div>
                            <CustomButton text="Apply now" className='btn_next width_100'></CustomButton>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
export default CarrierMatchTpl;