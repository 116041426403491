

import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const StackedBarChart = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const textColor = '#fff';
        const textColorSecondary = '#fff';
        const surfaceBorder = '#454545';
        const data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    type: 'bar',
                    label: 'Not selected',
                    backgroundColor: '#A155B9',
                    data: [50, 25, 12, 48, 90, 76, 42, 34, 22, 45, 67, 98]
                },
                {
                    type: 'bar',
                    label: '    selected',
                    backgroundColor: '#F765A3',
                    data: [21, 84, 24, 75, 37, 65, 34, 45, 87, 90, 11, 23]
                },
            ]
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor,
                    },
                    position: 'bottom'
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card" style={{ 'background': '#231B37' }}>
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    )
}
export default StackedBarChart;