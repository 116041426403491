import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { __getImage } from "../../../_factory/globalService";
const SelectInput = ({
    input,
    options,
    placeholder,
    valueKey,
    nameKey,
    isSingleAray,
    onChange,
    formValue = {},
    formKey = '',
    label,
    addonclassName = '',
    leftIcon = '',
    isAddon,
    isShowClear = true,
    isDisabled,
    meta: { touched, error },
}) => {
    return (
        <>
            <div className={`${isAddon ? 'addon_group ' : ' '}${addonclassName ? addonclassName : ''}`}>
                {leftIcon && <img src={__getImage(leftIcon)} />}
                <span className={`p-float-label width_100 ${isDisabled ? 'disabled' : ''}`}>
                    {isSingleAray ? <Dropdown disabled={isDisabled ? true : false} className={`form-control form-control-sm ${touched && error ? " is-invalid focus-visible" : ""
                        }`} value={input.value} onChange={(e) => {
                            input.onChange(e.target.value)
                            if (onChange) {
                                let temp = {
                                    e: e,
                                    formValue: formValue
                                }
                                onChange(temp, formKey);
                            }
                        }}
                        showClear={isShowClear}
                        placeholder={placeholder} options={options} optionLabel={nameKey} optionValue={nameKey} /> : <Dropdown disabled={isDisabled ? true : false} className={`form-control form-control-sm ${touched && error ? " is-invalid focus-visible" : ""
                            }`} value={input.value} onChange={(e) => {
                                input.onChange(e.target.value)
                                if (onChange) {
                                    let temp = {
                                        e: e,
                                        formValue: formValue
                                    }
                                    onChange(temp, formKey);
                                }
                            }}
                            showClear={isShowClear}
                            placeholder={placeholder} options={options} optionLabel={nameKey} optionValue={valueKey} />}

                    <label>{label || placeholder}</label>
                </span>
            </div>

            {touched && error && (
                <span className="error error-feedback">{error}</span>
            )}
        </>
    );
};
export default SelectInput;
