import React from "react";
import { __getImage } from "../../_factory/globalService";
export const TableSearchComponent = ({ placeholder = 'Search', handleSearch, searchInput }) => {
    const handleInputOnchange=(e)=>{
        handleSearch(e.target.value)
    }
    return <>
        <div className='search_bar'>
            <div className='custom-autocomplete width_icon search_inner'>
                <img className='search_icon' src={__getImage('search_icon.svg')} />
                <input className='form-control' placeholder={placeholder} value={searchInput} onChange={(e)=>{handleInputOnchange(e)}}/>
            </div>
        </div>
    </>
}
export default TableSearchComponent;