import React, { Fragment } from "react";
import { __getImage } from "../../../_factory/globalService";
import { InputText } from "primereact/inputtext";
const InputTextComponent = ({
    input,
    width,
    placeholder,
    isDisabled,
    maxlength,
    minlength,
    leftIcon = '',
    rightIcon = '',
    className = '',
    addonclassName = '',
    isAddon = false,
    label,
    meta: { touched, error }
}) => {
    return (
        <Fragment>
            {isAddon ? <div className={`addon_group ${addonclassName ? addonclassName : ''}`}>
                {leftIcon && <img src={__getImage(leftIcon)} />}
                <span className="p-float-label width_100">
                    <InputText
                        {...input}
                        placeholder={placeholder}
                        type={input.type}
                        width={width}
                        maxLength={maxlength}
                        minLength={minlength}
                        readOnly={isDisabled?true:false}
                        className={`form-control ${isDisabled ? 'disabled' : ''} ${className ? className : ''} ${touched && error ? " is-invalid focus-visible" : ""
                            }`}
                    />
                    <label>{label || placeholder}</label>
                </span>
                {rightIcon && <img src={__getImage(rightIcon)} />}
            </div> :
                <span className="p-float-label width_100">
                    <InputText
                        {...input}
                        placeholder={placeholder}
                        type={input.type}
                        width={width}
                        maxLength={maxlength}
                        minLength={minlength}
                        readOnly={isDisabled?true:false}
                        className={`form-control ${isDisabled ? 'disabled' : ''} ${className ? className : ''} ${touched && error ? " is-invalid focus-visible" : ""
                            }`}
                    />
                    <label htmlFor="username">{label || placeholder}</label>
                </span>
            }
            {touched && error && (
                <span className="error error-feedback">{error}</span>
            )}
        </Fragment>
    );
};

export default InputTextComponent;