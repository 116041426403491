import { apiConfig } from '../_factory/apiConfig';
import { HttpPost } from '../_factory/http-common';

export const __httpAllUserList = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.user.allUser;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}


export const __getMasterList = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.master.list;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

export const __saveMasterList = async (body, callback, errorCallback) => {
    try {
        let url = apiConfig.apiUrls.master.save;
        const res = await HttpPost(url, body)
        if (callback) {
            callback(res?.data);
        } else {
            return res
        }

    }
    catch (error) { console.log('error', error); if (errorCallback) errorCallback(error) }
}

