import * as React from "react";
import axios from "axios";
import { apiConfig } from "../../_factory/apiConfig";
import { __getImage } from "../../_factory/globalService";
const ImportFileComponent = ({
    placeholder,
    formData,
    emitDataUpload,
    isMultiple = false,
    formKey = 'fileUploadKey',
    imageId = 0,
    fleUploadConfig
}) => {
    // const { addToast } = useToasts();
    const [file, set_file] = React.useState([]);
    const [firstLoad, set_firstLoad] = React.useState(true);

    const [iconData] = React.useState([
        {
            icon:
                "https://icons.iconarchive.com/icons/treetog/file-type/128/pdf-icon.png",
            value: "pdf,PDF,application/pdf",
        },
        {
            icon:
                "https://icons.iconarchive.com/icons/dakirby309/simply-styled/128/Microsoft-Excel-2010-icon.png",
            value:
                "csv,CSV,xlsx,xls, xlx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        },
        {
            icon:
                "https://icons.iconarchive.com/icons/gakuseisean/aire/256/Images-icon.png",
            value:
                "image/jpeg, image/png, image/jpg, jpg",
        },
    ])
    React.useEffect(() => {
        if (imageId && firstLoad) {
            getImage(imageId)
        }
    }, [imageId])

    const uploadMultipleFiles = (e) => {
        let uploadFormData = new FormData();
        uploadFormData.append("xlsx", e.target.files[0]);
        let url = `${apiConfig.apiBaseUrl}${apiConfig.apiUrls.student.import}`;
        axios.post(url, uploadFormData).then((res) => {
            var filedata;
            if (e.target.files && e.target.files.length > 0) {
                filedata = iconData.filter((x) =>
                    x.value.includes(e.target.files[0].type)
                );
            }
            let fileArray = [...file]
            if (filedata && filedata[0] && filedata[0].icon) {
                if (isMultiple) {
                    if (filedata.length > 0) {
                        let item = {
                            ...res.data.result,
                            filePath: filedata[0].icon || URL.createObjectURL(e.target.files[0])
                        }
                        fileArray.push(item);
                    } else {
                        let item = {
                            ...res.data.result,
                            filePath: URL.createObjectURL(e.target.files[0])
                        }
                        fileArray.push(item);
                    }
                } else {
                    let item = {
                        ...res.data.result,
                        filePath: filedata[0] && filedata[0].icon || URL.createObjectURL(e.target.files[0])
                    }
                    fileArray = [];
                    fileArray.push(item);
                }

                set_file(fileArray);
                let config = {
                    body: 'User Data saved successfully',
                    isSuccess: true,
                }
                emitDataUpload(config)
                // if (emitDataUpload && fleUploadConfig) {
                //     // addToast('File uploaded', { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
                //     emitDataUpload(fileArray, null, null, fleUploadConfig)
                // } else if (emitDataUpload && formData) {
                //     // addToast('File uploaded', { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
                //     emitDataUpload(fileArray, formData, formKey)
                // } else {
                //     return
                // }
            } else {
                let config = {
                    body: 'Something went wrong',
                    isSuccess: false,
                }
                emitDataUpload(config)
                // addToast('File type not supported', { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
            }

        });
    }
    // program to get the file extension

    const getFileExtension = (filename) => {
        const extension = filename.split('.').pop();
        return extension;
    }
    const getImage = async (id) => {
        // set_firstLoad(false)
        // let url = `/api/Document/bulk?id=${id}`
        // let res = await Agent.HWCMonitoring.HttpFetch(url, {});
        // if (res && res.data && res.data.length > 0) {
        //     let fileArray = []
        //     res.data.map((data, index) => {
        //         let type = getFileExtension(data.fileName);
        //         let filedata = iconData.filter((x) =>
        //             x.value.includes(type)
        //         );
        //         let item = {
        //             ...data,
        //             filePath: filedata[0].icon
        //         }
        //         fileArray.push(item);
        //     })
        //     set_file(fileArray);
        // }
    }


    const handleFileDelete = async (id, index) => {
        // const url = `${apiConfig.apiUrls.document.delete}${id}`;
        // let response = await Agent.HWCMonitoring.HttpDel(url)
        // if (response && (response.status == 200) && (response.data && response.data.messege == "Success")) {
        //     let fileArray = [...file]
        //     fileArray.splice(index, 1);
        //     set_file(fileArray);
        //     if (emitDataUpload && formData) {
        //         addToast('File Removed successfully', { appearance: "success", autoDismiss: true, autoDismissTimeout: 3000 });
        //         emitDataUpload(fileArray, formData, formKey)
        //     }
        // }
    }

    const handleFileDownload = async (item, index) => {
        // let url = `/api/Document/Download?id=${item.id}`
        // await Agent.HWCMonitoring.HttpDownload(url, item.title);

    }
    return (
        <React.Fragment>
            <div className="form-group file-upload w-100">
                <label className="form-control w-100">
                    {placeholder || 'Upload Document'}
                    <img title="Upload" className="upload-icon" src={__getImage('filtersearch.svg')} />
                    <input
                        type="file"
                        className="upload"
                        onChange={(e) => { uploadMultipleFiles(e) }}
                    />
                </label>
            </div>
            <div className="form-group multi-preview">
                {file && file.length > 0 && file.map((item, index) => {
                    return (

                        <div className="image-box"><img
                            src={item.filePath}
                            className="multi-img-preview"
                            style={{ maxHeight: "100px" }}
                        />

                            <i className="fa fa-trash" onClick={() => { handleFileDelete(item.id, index) }}></i>
                            <i className="fa fa-download" onClick={() => { handleFileDownload(item, index) }}></i>
                        </div>

                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default ImportFileComponent;