import React from 'react';
import { __httpEnrolmentList } from '../../Enrolments/enrolmentService';
import Card from '../../../Components/Card';
import { __getImage, __getLoginDetail, __startEndIndex } from '../../../_factory/globalService';
import style from './listinig.module.scss';
import { Link } from "react-router-dom";
import { __httpAppliedJobList } from '../JobServicec';
import dateFormat from "dateformat";
import JobPostView from '../JobPostForm/JobPostView';
import Paging from '../../../Components/Paging';
import TableSearchComponent from '../../../Components/TableSearch';
const AppliedJobListComponent = () => {
    const [listData, set_listData] = React.useState(null);
    const [filteredListData, set_filteredListData] = React.useState(null);
    const [isDataLoaded, set_isDataLoaded] = React.useState(false);
    const [formViewDialogConfig, set_formViewDialogConfig] = React.useState(null);
    const [activePage, set_activePage] = React.useState(0);
    const [perPage] = React.useState(10);
    const [startIndex, set_startIndex] = React.useState(0);
    const [maxIndex, set_maxIndex] = React.useState(0);
    const [searchInput, set_searchInput] = React.useState('');
    React.useEffect(() => {
        let userData = __getLoginDetail();
        if (userData && userData.id) {
            getListData(userData.id)
        }
    }, [])
    React.useEffect(() => {
        filterListingData(searchInput)
    }, [listData])
    React.useEffect(() => {
        const startEndIndex = __startEndIndex(activePage, perPage);
        set_startIndex(startEndIndex?.startIndex);
        set_maxIndex(startEndIndex?.endIndex)
    }, [activePage])
    const getListData = (id) => {
        __httpAppliedJobList(id).then(res => {
            if (res && res.data && (res.data.isSuccess && res.data.responseData)) {
                set_listData(res.data.responseData)
            } else {
                set_listData(null)
            }
            set_isDataLoaded(true)
        })
    }
    const filterListingData = (val = '') => {
        let filteredData = [];
        if (val === "") {
            filteredData = listData;
        } else {
            filteredData = listData.filter((item) => {
                let obj = item.StudentDetail;
                let name = obj.name.toLowerCase()
                return name.includes(val.toLowerCase());
            });
        }
        set_filteredListData(filteredData)
    }
    const openJobView = (data) => {
        let temp = {
            isOpen: true,
            isReload: true,
            title: 'View Job Detail',
            formValue: {
                ...data
            }
        }
        set_formViewDialogConfig(temp)
    }

    const handleOnCloseViewCallback = () => {
        set_formViewDialogConfig(null)
    }
    const handleOnPaginationCallback = (pageNumber) => {
        set_activePage(pageNumber)
    }
    const handleSearch = (value) => {
        set_searchInput(value);
        filterListingData(value)
    }
    return (<>
        <Card>
            <div className={style['enrollment_wrapper']}>
                <div className='row plr-15'>
                    <div className='col-md-6 col-sm-12'>
                        <div className=''>
                            <TableSearchComponent placeholder='Search by name' searchInput={searchInput} handleSearch={handleSearch} />
                        </div>
                    </div>
                </div>
                <div className={style['page_header']}>
                    <div>
                        <div className={style['page_title']}>
                            List of Applied Job's
                        </div>
                        {isDataLoaded && listData && <div className={style['count']}>{listData.length} Record{listData.length > 9 ? 's' : ''}</div>}
                    </div>
                    <div className='d-flex'>
                        <button type='button' className={`ml-10 ${style['btn_primary']}`}>
                            <img src={__getImage('filtersearch.svg')} />
                            Filter
                        </button>
                    </div>
                </div>
                <div className={`table_wrapper scrollBar ${style['table_wrapper']}`}>
                    <table id="saleReportTable">
                        <thead>
                            <tr>
                                <th rowSpan={2} style={{ 'border-right': '1px white solid' }}>
                                    <div className='content'>
                                        Employee Details
                                    </div>
                                </th>
                                <th colSpan={7}>
                                    <div className='content text-center'>
                                        Job Details
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                {/* job */}
                                <th>
                                    <div className='content'>
                                        Title
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Industry
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Qualifications
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Employee Type
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Salary
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Location
                                    </div>
                                </th>
                                <th>
                                    <div className='content'>
                                        Applied On
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isDataLoaded ? <tr>
                                <td colSpan={100}>
                                    <div className='content'>

                                    </div>
                                </td>
                            </tr> : <>
                                {filteredListData && filteredListData.length > 0 ? <>
                                    {filteredListData && filteredListData.slice(startIndex, maxIndex).map((ele, i) => {
                                        return (
                                            <tr key={`apj_${i}`}>
                                                <td>
                                                    <Link to={`/enrolment/student/detail/${ele?.StudentDetail?.id}`}>
                                                        <div className='d-flex pointer'>
                                                            <div className={'thumb'}>
                                                                <img src={__getImage('Group.png')} />
                                                            </div>
                                                            <div className='content'>
                                                                {ele?.StudentDetail?.name}
                                                                <p className={'light'}>
                                                                    {ele?.StudentDetail?.email}
                                                                </p>
                                                                <p className={'light'}>
                                                                    {ele?.StudentDetail?.department}
                                                                </p>
                                                                <p className={'light'}>
                                                                    {ele?.StudentDetail?.position}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>

                                                {/* job */}
                                                <td>
                                                    <div className='content pointer' onClick={() => { openJobView(ele?.jobDetail) }}>
                                                        {ele?.jobDetail?.title}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.jobDetail?.industry}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.jobDetail?.qualifications}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.jobDetail?.employeeType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.jobDetail?.salary}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.jobDetail?.location}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='content'>
                                                        {ele?.AppliedDate && dateFormat(ele?.AppliedDate, "dd/mm/yyyy")}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </> : <>
                                    <tr>
                                        <td colSpan={100}>
                                            <div className='content'>
                                                No records...
                                            </div>
                                        </td>
                                    </tr>
                                </>}
                            </>}
                        </tbody>
                    </table>
                </div>
                {isDataLoaded && filteredListData && filteredListData.length > 0 ? <div className='pagers'>
                    <div></div>
                    <Paging total={filteredListData.length} perPage={perPage} activePage={activePage} onPaginationCallback={handleOnPaginationCallback} />
                </div> : ''}
            </div>
        </Card>
        {formViewDialogConfig && formViewDialogConfig.isOpen && <JobPostView data={formViewDialogConfig} onCloseCallback={handleOnCloseViewCallback} />}
    </>)
}
export default AppliedJobListComponent;