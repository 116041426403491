import React from 'react';
import { __getImage } from '../../../_factory/globalService';
import DialogWrapper from '../../../Components/Dialog/DialogWrapper';
import { __saveMasterList } from '../../../_factory/masterService';
import { _httpSignUp } from '../../Auth/AuthService';
const AccountDetailComponent = ({ data, onCloseCallback }) => {
    const [initialValue, set_initialValue] = React.useState({});
    React.useEffect(() => {
        set_initialValue(data?.formValue || {})
    }, [])
    const handleOnClickCallBack = (e) => {
        if (onCloseCallback) {
            onCloseCallback(e)
        }
    }
    return (<>
        <DialogWrapper className={'width_900'}>
            <div className='modal-header'>
                <h5 className="modal-title">{data?.title}</h5>
                <div className='dialog-close'>
                    <img src={__getImage('/dialog_close.svg')} onClick={(e) => { handleOnClickCallBack(null) }} />
                </div>
            </div>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                        <div className="form-group">
                            <label>Type</label>
                            <div className='form-control view'>{initialValue?.type}</div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <div className="form-group">
                            <label>Name</label>
                            <div className='form-control view'>{initialValue?.name}</div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <div className="form-group">
                            <label>Email</label>
                            <div className='form-control view'>{initialValue?.email}</div>
                        </div>
                    </div>
                    {initialValue?.type == 'university' ? <div className='col-sm-12 col-md-6'>
                        <div className="form-group">
                            <label>Referral Code</label>
                            <div className='form-control view'>{initialValue?.referralCode}</div>
                        </div>
                    </div> : ''}
                    {initialValue?.type == 'employer' ? <>
                        <div className='col-sm-12 col-md-6'>
                            <div className="form-group">
                                <label>Company Name</label>
                                <div className='form-control view'>{initialValue?.companyName}</div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12'>
                            <div className="form-group">
                                <label>Company Description</label>
                                <div className='form-control view textarea'>{initialValue?.companyDescription}</div>
                            </div>
                        </div>
                    </> : ''}
                </div>
            </div>
        </DialogWrapper>
    </>)
}
export default AccountDetailComponent;