export const StudentDetailData = {
    profileCompletion: '85',
    assessmentArr: [
        {
            text: 'UI designer',
            value: '93'
        },
        {
            text: 'Java',
            value: '89'
        },
        {
            text: 'UI Developer',
            value: '88'
        },
        {
            text: 'React js',
            value: '75'
        },
        {
            text: 'Node js',
            value: '93'
        }
    ],
    careersMatchArr: [
        {
            name: 'ATP Techno center',
            position: 'UI Designer',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce interdum ligula ut ante congue malesuada...',
            salaryRange: 'Salary Range : RM 2,000 - RM 2,500',
            score: '97',
            match: 'Excellent match'
        },
        {
            name: 'Infoys',
            position: 'UI Designer',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce interdum ligula ut ante congue malesuada...',
            salaryRange: 'Salary Range : RM 2,000 - RM 2,500',
            score: '93',
            match: 'Good match'
        },
        {
            name: 'Logic tech',
            position: 'UI Designer',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce interdum ligula ut ante congue malesuada...',
            salaryRange: 'Salary Range : RM 2,000 - RM 2,500',
            score: '85',
            match: 'Good match'
        },
        {
            name: 'Logic tech',
            position: 'UI Designer',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce interdum ligula ut ante congue malesuada...',
            salaryRange: 'Salary Range : RM 2,000 - RM 2,500',
            score: '85',
            match: 'Good match'
        }
    ]
}