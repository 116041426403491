import * as XLSX from 'xlsx';
const checkIfJsonOrString = (data) => {
    var ret = true;
    try {
        JSON.parse(data)
    } catch (e) {
        ret = false
    }
    return ret
}
export const __getImage = (src) => {
    let _cdn = process.env.REACT_APP_CDN_URLS.split(',');
    let url = `${_cdn[0]}/static/media/${src}`;
    return url
}
export const __getImageurl = (folder, file) => {
    let _cdn = process.env.REACT_APP_IMAGE_URL;
    let url = `${_cdn}/${folder}/${file}`;
    return url
}
export const __localStorageSet = (key, data) => {
    let value;
    if (data == null || data == undefined) {
        return
    }
    if (!data || (data && typeof data == 'boolean') || (data && typeof data == 'string') || (data instanceof String)) {
        value = data
    } else {
        value = JSON.stringify(data)
    }
    localStorage && localStorage.setItem(key, value)

}

export const __localStorageGet = (key) => {
    let value = null;
    let data = null;
    if (typeof window !== undefined) {
        data = localStorage.getItem(key);
        if (data && typeof data == "boolean") {
            value = data
        } else {
            let isJSON = data && checkIfJsonOrString(data) || false;
            if (isJSON) {
                value = data && JSON.parse(data) || null
            } else {
                value = data
            }
        }
    }
    return value
}
export const __localStorageDel = (key) => {
    if (localStorage) {
        localStorage.removeItem(key)
    }

}
export const __logOut = (key) => {
    __localStorageDel(key)
}

export const __getLoginDetail = () => {
    const userData = __localStorageGet('userDataTM');
    return userData
}
export const __startEndIndex = (activePage, perPage) => {
    let tempStart = 0;
    tempStart = ((activePage * perPage))
    let tampMax = perPage;
    if (activePage < 1) {
        tampMax = perPage
    } else {
        tampMax = tempStart + perPage - 1
    }
    let temp = {
        startIndex: tempStart,
        endIndex: tampMax,

    }
    return temp
}

export const __ExportToExcel = (type, id, name, fn, dl) => {
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
        XLSX.writeFile(wb, fn || (`${name}.` + (type || 'xlsx')));
}