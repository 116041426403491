import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function DoughnutChart() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const data = {
            labels: ['Rescheduled', 'Missed', 'Cancelled', 'Completed'],
            datasets: [
                {
                    data: [15, 9, 8, 32],
                    backgroundColor: [
                        '#D86D8C', '#ED3FBB', '#C797FD', '#F88867'
                    ],
                    hoverBackgroundColor: [
                        '#D86D8C', '#ED3FBB', '#C797FD', '#F88867'
                    ]
                }
            ]
        };
        const options = {
            cutout: '89%',
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        fontSize: 14,
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets[0].data.map((data, i) => {
                                chart.legend.ctx.fillStyle = '#ffff'
                                /* datasets[0].backgroundColor[i] */
                                let item = {};
                                item = {
                                    text: `${chart.data.labels[i]} ${data}`,
                                    fillStyle: datasets[0].backgroundColor[i],
                                    index: i
                                }
                                return item

                            })
                        }
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, []);


    return (
        <div className="donut_chart_wrap">
            <div className='donut_chart_inner'>
                <div className='center-text'>
                    <div className='text_1'>
                        Interviews
                    </div>
                    <div className='text_2'>
                        64
                    </div>
                </div>
                <Chart type="doughnut" data={chartData} options={chartOptions} className="purple_chart" height={'300px'} />
            </div>
        </div>
    )
}
