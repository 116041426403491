import React, { Fragment } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
const InputTextAreaComponent = ({
    input,
    width,
    placeholder,
    isDisabled,
    className = '',
    rows,
    label,
    meta: { touched, error }
}) => {
    return (
        <Fragment>
            <span className="p-float-label width_100">
                <InputTextarea rows={rows}
                    {...input}
                    placeholder={placeholder}
                    readOnly={isDisabled}
                    width={width}
                    className={`form-control textarea ${className ? className : ''} ${touched && error ? " is-invalid focus-visible" : ""
                        }`} />
                <label>{label || placeholder}</label>
            </span>
            {touched && error && (
                <span className="error error-feedback">{error}</span>
            )}
        </Fragment>
    );
};

export default InputTextAreaComponent;